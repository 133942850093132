* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    padding: 4rem 6rem;
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .secion__margin {
        padding: 4rem;
    }
}

@media screen and (max-width: 520px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .secion__margin {
        padding: 4rem 2rem;
    }
}
