.chi-siamo-container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 10% 0;
    position: relative;
}

.chi-siamo-container::after {
    bottom: 0;
    content: '';
    background-color: #000;
    left: 0;
    opacity: .3;
    position: absolute;
    right: 0;
    top: 0;
}

.chi-siamo-content {
    position: relative;
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .chi-siamo-container {
        padding: 10% 0 5%;
    }
}
