@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;
  --color-bg: #510d22;
  --color-text: #FFFFFF;
  --color-subtext: #FFFFFF;
}

@font-face {
  font-family: 'ANDYFISH';
  src: url('./assets/fonts/ANDYFISH.woff') format('woff'),
    url('./assets/fonts/ANDYFISH.ttf') format('truetype');
  font-display: swap;
}

h1 {
  color: var(--color-text);
  font-family: 'ANDYFISH', sans-serif;
  font-size: 60px;
  line-height: 65px;
  text-transform: uppercase;
}

h2 {
  color: var(--color-text);
  font-family: 'ANDYFISH', sans-serif;
  font-size: 35px;
  line-height: 40px;
  margin: 0 0 2rem;
  text-transform: uppercase;
}

p {
  color: var(--color-text);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
}

a {
  color: var(--color-text);
  font-family: 'Manrope', sans-serif;
  text-decoration: none;
}

@media screen and (max-width: 700px) {
  h1 {
    font-size: 50px;
    line-height: 55px;
  }

  h2 {
    font-size: 30px;
    line-height: 35px;
    margin: 0 0 1rem;
  }
}


/* COMMON ANIMATION CLASSES */

/* START - Menu scale up */

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
  }

  100% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}

/* END - Menu scale up */

/* START - Text flicker in */

.text-flicker-in-glow {
  -webkit-animation: text-flicker-in-glow 4s linear both;
  animation: text-flicker-in-glow 4s linear both;
}

@-webkit-keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
    text-shadow: none;
  }

  10.1% {
    opacity: 1;
    text-shadow: none;
  }

  10.2% {
    opacity: 0;
    text-shadow: none;
  }

  20% {
    opacity: 0;
    text-shadow: none;
  }

  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }

  20.6% {
    opacity: 0;
    text-shadow: none;
  }

  30% {
    opacity: 0;
    text-shadow: none;
  }

  30.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  30.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  30.6% {
    opacity: 0;
    text-shadow: none;
  }

  45% {
    opacity: 0;
    text-shadow: none;
  }

  45.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  55% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  55.1% {
    opacity: 0;
    text-shadow: none;
  }

  57% {
    opacity: 0;
    text-shadow: none;
  }

  57.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }

  60% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }

  60.1% {
    opacity: 0;
    text-shadow: none;
  }

  65% {
    opacity: 0;
    text-shadow: none;
  }

  65.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  75% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  75.1% {
    opacity: 0;
    text-shadow: none;
  }

  77% {
    opacity: 0;
    text-shadow: none;
  }

  77.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  85% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  85.1% {
    opacity: 0;
    text-shadow: none;
  }

  86% {
    opacity: 0;
    text-shadow: none;
  }

  86.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  100% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
}

@keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
    text-shadow: none;
  }

  10.1% {
    opacity: 1;
    text-shadow: none;
  }

  10.2% {
    opacity: 0;
    text-shadow: none;
  }

  20% {
    opacity: 0;
    text-shadow: none;
  }

  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }

  20.6% {
    opacity: 0;
    text-shadow: none;
  }

  30% {
    opacity: 0;
    text-shadow: none;
  }

  30.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  30.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  30.6% {
    opacity: 0;
    text-shadow: none;
  }

  45% {
    opacity: 0;
    text-shadow: none;
  }

  45.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  55% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }

  55.1% {
    opacity: 0;
    text-shadow: none;
  }

  57% {
    opacity: 0;
    text-shadow: none;
  }

  57.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }

  60% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }

  60.1% {
    opacity: 0;
    text-shadow: none;
  }

  65% {
    opacity: 0;
    text-shadow: none;
  }

  65.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  75% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  75.1% {
    opacity: 0;
    text-shadow: none;
  }

  77% {
    opacity: 0;
    text-shadow: none;
  }

  77.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  85% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  85.1% {
    opacity: 0;
    text-shadow: none;
  }

  86% {
    opacity: 0;
    text-shadow: none;
  }

  86.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }

  100% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
}

/* END - Text flicker in */

/* START - Slide in */
.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-fwd-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/* END - Slide in */