.header .header-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
}

/* @TODO: sostituire css quando verrà messo il logo al posto dell'h1 */
.header .header-content .header-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
