.maintenance-video {
    width: 100%;
    height: 100vh;
}

.maintenance-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
